<template>
    <el-dialog
    v-model="dialogVisible"
    title="审核"
    width="700">
    <div>
        <div class="containTitle">
            <div class="title">注册内容</div>
            <div class="titleLine"></div>
        </div>
        <div class="containBox">
            <div>账号 : {{ data.username }}</div>
            <div>博物馆 : {{ data.site }}</div>
            <div>姓名 : {{ data.name }}</div>
            <div>联系电话 : {{ data.phone }}</div>
            <div>邮箱: {{ data.emial }}</div>
            <div class="chartBox">
                资质文件:
                <div class="imgBox">
                    <el-image 
                    v-for="(item, index) in data.files" 
                    style="width: 200px; 
                    height:200px; 
                    margin-right: 10px;
                    background-color: rgb(139, 139, 139);" 
                    :preview-src-list="data.files"
                    :initial-index="index"
                    :src="item" 
                    fit="contain" />
                </div>
            </div>
        </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button color="#803a15" dark @click="pubilcFun">审核通过</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { register_detiles, register_fb } from '@/api/register.js'
export default {
    emits:['OK'],
    components:{
    },
    data(){
        return{
            type:0,
            dialogVisible:false,
            id:null,
            data:{},
        }
    },
    mounted(){
        
    },
    methods:{
        open(data){
            this.dialogVisible = true
            register_detiles(data.id).then(r=>{
                this.data = r.data
            })
        },
        pubilcFun(){
            register_fb(this.data.id).then(r=>{
                if(r.code==200){
                    this.$message.success('审核成功')
                    this.dialogVisible = false
                    this.$emit('OK',1)
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.containTitle{
    display: flex;
    justify-content: flex-start;
    .titleLine{
        height: 30px;
        width: calc(100% - 70px);
        box-sizing: border-box;
        border-bottom: 1px solid rgb(173, 173, 173);
    }
    .title{
        width: 70px;
        line-height: 30px;
        box-sizing: border-box;
        color: rgb(32, 72, 252);
        height: 30px;
        border-bottom: 2px solid rgb(32, 72, 252);
        font-weight: bold;
    }
}
    .containBox{
    margin-top: 20px;
    line-height: 30px;
    }
.chartBox{
    .ImgBox{
        width: 100%;
    }
}
</style>