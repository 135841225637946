import request from '@/utils/request'
export function register_List(data) { //列表
  return request({
    url: '/register/queryRegister',
    method: 'get',
    params:data
  })
}


export function register_detiles(id) { //详情
    return request({
      url: `/register/detail?id=${id}`,
      method: 'get',
    })
  }


  export function register_fb(id) { //审核通过
    return request({
      url: `/register/review?id=${id}`,
      method: 'get',
    })
  }


  export function register_del(id) { //删除
    return request({
      url: `/register/delete?id=${id}`,
      method: 'get',
    })
  }
  